<template>
    <div id="v-pills-comments" role="tabpanel" aria-labelledby="v-pills-comments-tab">
       <div class="col content">
          <h5 v-if="data.comment_count && data.rating_count"><strong>Employer rating &amp; Comments</strong></h5>
          <h5 v-else>No Rating &amp; Comments from employers till now</h5>

          <div class="container p-3">
            <div  v-if="data.comment_count && data.rating_count">
              <p><span class="text-primary" style="font-size:1.5rem"><strong>{{parseFloat(data.rating_avg).toFixed(1)}}</strong></span> rating from <span style="font-size:1.25rem"><strong>{{data.rating_count}}</strong></span> employers</p>
              <p>{{data.comment_count}} comment(s) by employers</p>
            </div>
            <div v-else>
              <p>Suggest applying for more matching jobs to notice your profile by potential employers.</p>
            </div>
          </div>
          <div class="comment-static px-3">
            <p>Employer ratings &amp; comments will help to understand how many employers reviewed your profile in detail &amp; their overall feedback on your profile.</p>
            <p>Ratings by employers are their self-rating on each candidate's profile. This may depend on their preferences, matching their job roles etc.</p>
            <p>Comments are internal notes each employer posts against a jobseeker profile for future reference. These are notes they may refer to during their shortlisting &amp; next hiring steps.</p>
          </div>
       </div>
    </div>
</template>

<script>
import Actions from '../libraries/apiActions.js';
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import '../assets/hire.css';
export default {
  name: 'Comments',
  data(){
    return{
      action: new Actions(),
      data: {
        comment_count: 0,
        rating_count: 0,
        rating_avg: 0
      },
      total: 0,
    }
  },
  beforeMount(){
    this.loadComments();
  },
  methods:{
    loadComments() {
      let token = this.$cookies.get("hire-seeker").token;
      this.action.getComments(token).then((res) => {
              this.data.comment_count = res.data.comments.comment_count;
              this.data.rating_count = res.data.rating.count;
              this.data.rating_avg = res.data.rating.average;
      }).catch((err) => {
          console.log(err)
      });
    },
    getTime(ts) {
        var timestamp = ts * 1000;
        var date = new Date(timestamp);
        return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    },
    checkDate(ts) {
          let exp_ts = ts + 1800;
          let current_time = new Date().getTime()/1000
          if(current_time < ts) return 'upcoming';
          else if(ts < current_time && current_time< exp_ts) return 'active';
          else if(current_time > exp_ts) return 'expired';
      },
  }
}
</script>
<style>
  .btn.expired {
    font-size: 0.75rem;
    color: white;
    background-color: #7cafcf ;
    text-transform: capitalize;
    /* cursor: not-allowed !important; */
  }

  .btn.active, .btn.upcoming {
      color: white;
      font-size: 0.75rem;
      background-color: green;
      text-transform: capitalize;
  }
  .job_desgination {
    color:#0054ad;
    font-weight: bold;
  }
  .expired, .expired p{
    color: #8f8f8f;
  }
</style>
